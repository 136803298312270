#ClassicButton {
    border: 1px solid #dde1e7;
    transition: 0.3s;
    background-color: var(--secondary_bg_color);
    box-shadow: var(--primary_box_shadow);
    padding: 7px 20px;
    margin: 10px;
    font-weight: 400;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#ClassicButton:hover {
    /* box-shadow: var(--hover_box_shadow); */
    background-color: var(--thirth_opposite_inner_color);
}

#ClassicButton .ClassicButton-icon {
    max-width: 30px;
    height: auto;
    width: auto;
    max-height: 35px;
    margin-right: 10px;
    padding: 0
}

#ClassicButton .ClassicButton-icon * {
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 100%;
}