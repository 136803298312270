@keyframes PageLoading {
    0% {
        opacity: 1;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1.5, 1.5);
    }

    100% {
        opacity: 0;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1, 1);
    }
}

#PageLoading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#PageLoading .PageLoading-loading-inner div>div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #5367ed;
    animation: PageLoading 1s linear infinite;
}

#PageLoading .PageLoading-loading-inner div:nth-child(1)>div {
    left: 148px;
    top: 88px;
    animation-delay: -0.875s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 160px 100px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(2)>div {
    left: 130px;
    top: 130px;
    animation-delay: -0.75s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 142px 142px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(3)>div {
    left: 88px;
    top: 148px;
    animation-delay: -0.625s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 100px 160px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(4)>div {
    left: 46px;
    top: 130px;
    animation-delay: -0.5s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 58px 142px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(5)>div {
    left: 28px;
    top: 88px;
    animation-delay: -0.375s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 40px 100px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(6)>div {
    left: 46px;
    top: 46px;
    animation-delay: -0.25s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 58px 58px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(7)>div {
    left: 88px;
    top: 28px;
    animation-delay: -0.125s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 100px 40px;
}

#PageLoading .PageLoading-loading-inner div:nth-child(8)>div {
    left: 130px;
    top: 46px;
    animation-delay: 0s;
}

#PageLoading .PageLoading-loading-inner>div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 142px 58px;
}

.PageLoading-loading-spinner {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

#PageLoading .PageLoading-loading-inner {
    width: 100%;
    height: 100%;
    position: relative;
    backface-visibility: hidden;
}

#PageLoading .PageLoading-loading-inner div {
    box-sizing: content-box;
}