#PageStructure {
    width: 100%;
    height: 100%;
    position: fixed;
}

#PageStructure .PageStructure-cover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    flex-direction: row;
    /* background-color: red; */
}

#PageStructure .PageStructure-cover .PageStructure-left_side_menu {
    z-index: 999999;
    position: relative;
}

#PageStructure .PageStructure-cover .PageStructure-right_side_page_content {
    transition: 0.5s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    flex-direction: column;
}

#PageStructure .PageStructure-cover .PageStructure-right_side_page_content .PageStructure-top_navbar_menu {
    width: 100%;
}

#PageStructure .PageStructure-cover .PageStructure-right_side_page_content .PageStructure-bottom_page_content {
    height: 100%;
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    padding-top: 10px;
    position: relative;
    overflow-y: auto;
}
