@media only screen and (max-width: 850px) {
    #SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-buttons-and-info-section {
        flex-wrap: wrap !important;
    }
    .sensors-info {
        margin-bottom: 20px;
    }
}

#SingleGtwDetail {
    /* background-color: var(--LDColor); */
    width: 100%;
}

#SingleGtwDetail .SingleGtwDetail-cover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

#SingleGtwDetail .SingleGtwDetail-cover.isTabletOrMobile {
    // background-color: red;
}

#SingleGtwDetail .SingleGtwDetail-cover.isDesktopOrLaptop {
    /* background-color: var(--LDColor); */
    // background-color: #329bd7;
}

/* ******* */
/* GTW SENSOR INFOS */
/* ******* */

#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-buttons-and-info-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1500px;
    width: 100%;
    .sensors-info {
        background-color: white;
        // box-shadow: 0px 10px 19px -4px rgba(0, 0, 0, 0.1);
        // background-color: red;

        .title {
            font-weight: 800;
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
            margin-bottom: 25px;
            padding: 5px 0;
        }
        .content {
            padding-left: 10px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;

            .content-headers {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100px;

                span {
                    margin-bottom: 10px;
                    font-size: 13px;
                }
            }
            .content-values {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                span {
                    margin-bottom: 10px;
                    font-weight: 900;
                    font-size: 13px;
                }
            }
        }
    }
    .buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: auto;

        .button {
            // background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 500;
            border-radius: 10px;
            margin: 0 15px 15px 0;
            padding: 7px 12px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            transition: 0.4s;
            // border: 1px solid #20bb30;
            // color: black;
        }
        a {
            // color: black;
        }

        .button.reset-buttons {
            // border: 1px solid #b86571;
        }

        .button:hover {
            /* box-shadow: 0px 4px 15px -3px rgba(77, 77, 77, 0.4); */

            box-shadow: 0px 10px 15px -3px rgba(77, 77, 77, 0.4);
            background-color: #fefefffd;
        }
    }
}

/* GTW SENSOR INFOS  isDesktopOrLaptop*/
#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-buttons-and-info-section.isDesktopOrLaptop {
    flex-direction: column;
    .sensors-info {
        padding: 20px;
        min-width: 350px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
}

/* GTW SENSOR INFOS  isTabletOrMobile*/
#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-buttons-and-info-section.isTabletOrMobile {
    flex-direction: column;
    .sensors-info {
        padding: 5px 15px;
        border-radius: 7px;
        min-width: 280px;
        .title {
            margin-bottom: 15px;
        }
    }
    .buttons {
        margin-top: 10px;

        .button {
            border-radius: 7px;
            margin: 0 10px 10px 0;
            padding: 7px 12px;
            transition: 0.3s;
        }
    }
}

/* GTW SENSOR INFOS  isPortrait*/
#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-buttons-and-info-section.isPortrait {
    flex-direction: column;

    .sensors-info {
        width: 100%;
        .title {
            text-align: center;
        }
        .content {
            justify-content: center;
        }
    }
    .buttons {
        margin-left: 0px;
        justify-content: space-between;

        .button {
            font-size: 12px;
            width: 48%;
            border-radius: 6px;
            margin: 0 5px 5px 0;
            padding: 7px 12px;
            transition: 0.3s;
        }
    }
}

/* ******* */
/* GTW SENSOR ROOMS INFOS */
/* ******* */

#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-sensors-info-section {
    // background-color: white;
    margin-top: 10px;
    width: 100%;
    padding: 15px;
    padding-top: 0;
    max-width: 1500px;
    min-width: 300px;
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .SingleGtwDetail-sensors-info-right-side {
        // border-left: 1px solid #e3e7ec;
        min-width: max-content;
        table {
            width: max-content;
            // background-color: red;
            margin-bottom: 0 !important;
            thead {
                // box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
                tr {
                    th {
                        font-weight: 500;
                        font-size: 13px;
                        border-bottom: 1px solid #b1b1b144;
                    }
                }
            }
            tbody {
                &:before {
                    display: none;
                }
                tr {
                    th {
                    }
                    td {
                    }
                    td.nodes {
                        div.single-node {
                            // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                            padding: 5px 10px;
                            .node-info {
                                margin: 5px 0;
                                .node-name {
                                    font-weight: 600;
                                }
                                .node-id {
                                    margin-left: 5px;
                                    margin-bottom: 10px;
                                    font-weight: 800;
                                    font-size: 13px;
                                    // color: #329bd7;
                                }
                                .node-ver {
                                    font-weight: 600;
                                }
                                .node-ver-no {
                                    margin-left: 5px;
                                    font-weight: 800;
                                    font-size: 13px;
                                    // color: #329bd7;
                                }
                            }
                        }
                    }
                }
                .last-td {
                // box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
                }
            }
        }
    }
}

/* GTW SENSOR ROOMS INFOS isDesktopOrLaptop*/
// #SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-sensors-info-section.isDesktopOrLaptop {
// }

/* GTW SENSOR ROOMS INFOS isTabletOrMobile*/
#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-sensors-info-section.isTabletOrMobile {
    margin-top: 10px;
    padding: 10px;
    border-radius: 7px;
}

/* GTW SENSOR ROOMS INFOS isPortrait*/
#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-sensors-info-section.isPortrait {
    margin-top: 10px;
    padding: 5px;
    padding-left: 0;
    min-width: 0px;
}

/* ******* */
/* GTW SENSOR LAST FİVE DATA */
/* ******* */

#SingleGtwDetail .SingleGtwDetail-cover .SingleGtwDetail-support-tickets-data {
    // background-color: white;
    margin-top: 20px;
    max-width: 1500px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: auto;
    .SingleGtwDetail-support-tickets-data-cover {
        min-width: 1200px;

        .support-ticket-detail {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}


.last-td {
    border-bottom: 1px solid #b1b1b16b
}

.table thead tr {
    border-bottom: 1px solid #b1b1b144
}

.table tr:last-child {
    border-bottom: none !important;
}


.custom-select .ant-select-selector {
    height: auto !important;
    padding: 4px 12px !important;  // Bu padding değerini kendi tasarımınıza göre ayarlayabilirsiniz
}

.custom-select .ant-select-selected-value {
    display: flex;
    height: auto;
}

@media screen and (max-width: 430px) {
    .ant-picker-panels {
        flex-direction: column;
        position: fixed;
        top: 110px;
        background-color: white;
        border-radius: 10px;
    }
}

.table {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;

    td {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
    }
}