#NavbarMenu {
    /* background-color: var(--secondary_swatch); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px !important;
    width: 100%;
    position: relative;
}

#NavbarMenu .NavbarMenu-menu_icon {
    margin-left: 0px;
    width: max-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#NavbarMenu .NavbarMenu-menu_icon i {
    color: var(--primary_opposite_inner_color);
    padding: 6px 8px;
    margin: 0 15px;
    cursor: pointer;

}

#NavbarMenu .NavbarMenu-menu_icon span {
    width: 100%;
    margin-left: 5px;
    font-weight: 800;
    color: var(--primary_text_color);
}

#NavbarMenu .NavbarMenu-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#NavbarMenu .NavbarMenu-items div {
    margin-right: 20px;
}

#NavbarMenu .NavbarMenu-items div i {
    color: var(--primary_icon_color);
}

#NavbarMenu .NavbarMenu-items div span {
    color: var(--primary_text_color);
    font-weight: 500;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-username {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-username span {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-region {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-region span {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-mail_box {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-mail_box i {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings {
    padding: 0px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu {
    position: absolute;
    width: max-content;
    margin-top: 82px;
    padding: 10px 15px;
    z-index: 9;
    text-align: center;
    display: none;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    padding: 15px 20px;
    text-align: center;
    width: 100%;
    border-radius: 4px;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    border-radius: 4px;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item:hover div {
    background-color: var(--secondary_swatch);
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item:hover div i,
#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item:hover div span {
    color: white;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item i {
    color: black;

}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings .NavbarMenu-settings_menu .NavbarMenu-settings_menu_item span {
    margin-left: 10px;
    color: black;
    font-size: 12px;
    font-weight: 700;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-settings i {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-user_icon {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-user_icon i {}

#NavbarMenu .NavbarMenu-items .NavbarMenu-logout {
    padding: 0px 5px;
    cursor: pointer;
}

#NavbarMenu .NavbarMenu-items .NavbarMenu-logout i {}