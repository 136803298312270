#LoginLoading {
    position: absolute;
    background-color: rgba(158, 158, 158, 0.349);
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
}

#LoginPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#LoginPage .LoginPage_Bg_Img {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-repeat: repeat-x;
    background-position: left;
    background-attachment: fixed;
    background-size: cover;
    transform: scale(1);
    z-index: 1;
}



#LoginPage h3 {
    font-size: 20px;
    color: var(--LDColor4);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
}

#LoginPage h6 {
    font-size: 16px;
    color: var(--LDLightGrayColor3);
}


#LoginPage form {
}

#LoginPage .LoginPage_LoginCard {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    max-height: 600px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: relative;
    z-index: 3;
    padding: 0 20px;
}

#LoginPage .LoginPage_LoginCard .LoginPage_LoginCard_blur {
    position: absolute;
    filter: blur(.3rem);
    max-width: 450px;
    max-height: 450px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: white;
}

#LoginPage .LoginPage_LoginCard .LoginPage_LoginCard_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
    background-color: transparent;
    margin: 0 20px;
}

.ant-form-item.css-dev-only-do-not-override-1nrjqmi{
    margin-bottom: 15px;
}