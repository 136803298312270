#SystemCheck {
    // background: linear-gradient(#fdd8de, #fdd8de, #fdd8de);
    // min-height: 40px;
    // color: #790619;
}

#SystemCheck .SystemCheck_fireAlarm {
    background: linear-gradient(#fff2f0, #fff2f0, #fff2f0);
    min-height: 40px;
    color: #1f1f1c;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffe58f;
    .icon {
        margin-right: 5px;
        img {
            height: 30px;
        }
    }
    .value {
        font-weight: 600;
        font-size: 13px;
        a {
            text-decoration: underline;
        }
    }
}

#SystemCheck .SystemCheck_openCoverAlarm {
    background: linear-gradient(#fffbe6, #fffbe6, #fffbe6);
    min-height: 40px;
    color: #1f1f1c;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffe58f;
    .icon {
        margin-right: 5px;
        img {
            height: 30px;
        }
    }
    .value {
        font-weight: 600;
        font-size: 13px;
        a {
            text-decoration: underline;
        }
    }
}
