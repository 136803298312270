.owner-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    .owner-info-2 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        .fullname-cover {
            span.fullname {
                width: auto;
                font-weight: 800;
                font-family: "PT Sans", sans-serif;
            }
        }
        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            span.user-company {
                margin-right: 30px;
                font-weight: 550;
                font-size: 12px;
            }
            span.time {
                width: auto;
                font-weight: 550;
                font-size: 12px;
            }
        }
    }
}
.ticket-message-font {
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
}