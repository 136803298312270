#UpdateConfigs {
    .table-sm > :not(caption) > * > * {
        padding: 5px;
    }
    .ant-input-number-handler-wrap { 
        visibility: hidden;
    }
}

.custom-panel {
    .ant-collapse-content {
        // background-color: white;
        overflow-x: scroll;
    }
}

.custom-table {
    thead {
        box-shadow: #b1b1b16b 0px 6px 5px -5px;
    }
    tbody {
        tr {
            border-bottom: #b1b1b16b 1px solid;
        }
        border-bottom: #b1b1b16b 1px solid;
    }
}