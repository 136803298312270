#OptimTable {
    background-color: white;
    padding-top: 10px;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    overflow: auto;

    .OptimTable-header {
        // width: calc(100% + 18px);
        min-width: 1200px !important;
        margin-right: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        font-family: 'Roboto', sans-serif ;
        .OptimTable-datePicker-cover {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            input {
                color: black !important;
                font-weight: 600 !important;
                background-color: #e8ebef !important;
                border: none !important;
                font-size: 13px !important;
                font-family: "dosis", sans-serif !important;
                padding: 5px;
                border-radius: 3px;
                // word-spacing: 5px;
                // letter-spacing: 1px;
                width: 140px !important;
                height: 30px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
        }
    }
    .OptimTable-body {
        overflow-x: hidden !important;
        min-width: 1200px !important;
        .OptimTable-body-item {
        font-family: 'Roboto', sans-serif ;
        min-width: 1200px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            padding-left: 1px;
            box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
            .item-content {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 5px;
                min-width: 50px;
                padding-left: 5px;
                font-size: 13px;
            }
            .item-content.not-overflow {
                overflow: visible;
                height: 100px !important;
                margin: 0 5px;
                padding: 5px 0;
                min-width: 50px;
                padding-left: 5px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                pre {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: pre-line;
                    height: calc(100% - 13px);
                }
            }
            .item-content-button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                margin: 0 1px;
                min-width: 30px;
                overflow: hidden;
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    width: 100%;
                    height: 30px;
                }
            }
        }
        .OptimTable-body-item:focus{
            background-color: #e7f1fe;
        }
    }
}
