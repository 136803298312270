#Settings {
    width: 100%;
    height: 100%;
    .Settings-cover {
        width: 100%;
        height: calc(100% - 10px);

        .settings-container {
            height: 100%;
            .settings-row {
                height: 100%;
                .settings-col {
                }
                .col-left {
                    // border: 1px solid #e0e0e0;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                    // background-color: #f5f7f9;
                    .title {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        height: 50px;
                        font-weight: 800;
                        font-size: 18px;
                        font-family: "Roboto Slab", serif;
                        cursor: pointer;
                    }
                    .card-settings {
                        margin-bottom: 20px;
                        div.ant-card-body {
                            padding: 5px 20px;
                        }
                        div.cord-menu-item {
                            width: 100%;
                            height: 40px;
                            border-radius: 5px;

                            div {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                flex-direction: row;
                                padding-left: 10px;
                                border-radius: 5px;
                                transition: 0.1s all;
                                cursor: pointer;
                                i {
                                    font-size: 16px;
                                    color: #137ab6;
                                    width: 35px;
                                }
                                span {
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                                &:hover {
                                    background-color: #5bc3ff31;
                                }
                            }
                        }
                        .active {
                            background-color: #5bc3ff31;
                        }
                    }
                }
                .col-right {
                    // border: 1px solid #e0e0e0;
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;

                    // background-color: white;
                    .title {
                        height: 50px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        // background-color: red;
                        padding-left: 20px;
                        font-weight: 800;
                        font-family: "Roboto Slab", serif;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .settings-col-right-content-cover {
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
            0 2px 4px 0 rgba(0, 0, 0, 0.02);
        width: calc(100%);
        height: auto;
        min-height: 60px;
        display: flex;
        padding: 20px;
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 768px) {
    .settings-col-right-content-cover {
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
            0 2px 4px 0 rgba(0, 0, 0, 0.02);
        width: calc(100% - 20px);
        height: auto;
        margin-left: 20px;
        min-height: 60px;
        display: flex;
        padding: 20px;
    }   
}