#LeftSideMenu {
    position: relative;
    background-color: var(--primary_swatch);
    height: 100vh;
    width: 60px;
    transition: 0.3s;
}

#LeftSideMenu .LeftSideMenu-cover {}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item-logo {
    width: 60px;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item-logo img {
    width: 60px;
    height: 100%;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus :not(.LeftSideMenu-item-logo) img {
    padding: 6px 0;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item {
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 5px;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item span {
    margin-left: 10px;
    color: var(--primary_text_color);
    font-size: 11px;
    width: 100%;
    white-space: pre;
    float: left;
    text-align: left;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item:hover {
    background-color: var(--secondary_swatch);
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item svg {
    fill: var(--primary_bg_color);
    width: 22px;
    height: 40px;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item i {
    height: 40px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item.LeftSideMenu-item_active {
    background-color: #329bd7;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item .inner-item:hover {
    background-color: #5367ed;
    color: white;
    border-radius: 5px;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item .inner-item:hover * {
    color: white !important;
    font-weight: 900 !important;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item .inner-item i {
    /* width: 200px; */
    padding-left: 15px;
}


#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item.more_menu {}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item.more_menu .more_menu_items {
    padding: 10px;
    width: 200px !important;
}

#LeftSideMenu .LeftSideMenu-cover .LeftSideMenu-menus .LeftSideMenu-item.more_menu .more_menu_items a {}

.menu-items {
    font-size: 20px;
    margin-left: 1px;
    color: white;
}