
.ant-modal.css-dev-only-do-not-override-1n7nwfa {
    min-width: 300px !important;
    margin: 16px !important;
}
.ant-modal-content{
    margin: 16px !important;
}
.ant-form-item.css-dev-only-do-not-override-1n7nwfa {
    // margin-bottom: 16px !important;
}
.ant-modal-header {
    margin-bottom: 20px !important;
}
// #AntdModal {
// }

// Footerdaki butonlar ile ilgili
.footer_buttons_part {
    // .ant-col.ant-form-item-label.css-dev-only-do-not-override-1n7nwfa {
    //     display: none;
    // }

    .ant-form-item.css-dev-only-do-not-override-1n7nwfa {
        margin-bottom: 0;
    }
}

// .ant-input.css-dev-only-do-not-override-11cdk7n,
// .ant-input-number-input,
// .ant-select-selection-search-input,
// .ant-select-selector {
//     background-color: #e8ebefb6 !important;
// }

// .ant-select-selector:has(.ant-select-selection-search-input:disabled),
// .ant-select-selection-search-input:disabled {
//     background-color: #f5f5f5 !important;
// }

// .ant-select-selector .ant-select-selection-placeholder {
//     color: #afafaf !important;
//     z-index: 2;
// }
// .ant-input-affix-wrapper.ant-input-affix-wrapper-has-feedback.css-dev-only-do-not-override-11cdk7n {
//     background-color: #e8ebefb6 !important;
// }


@media only screen and (max-width: 1220px) {
    .ant-modal-content{
        margin: 0px !important;
    }
    .ant-modal-root .ant-modal-centered .ant-modal{
        top: 10px !important;
    }
}
