.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999 !important;
}

#ClassicModal {
    /* background-color: red; */
    /* display: none; */
    /* width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgb(197, 197, 197);
    opacity: 0.8;
    left: 0;
    top: 0; */
    background-color: transparent !important;
    height: 100vh !important;
    /* transition: 12s !important; */
    /* transition-duration: 12s !important; */
    border: none !important;
    padding: 0 0 !important;
    overflow: auto !important;
    inset: 0 !important;
    width: 100vw !important;
    /* height: min-content !important; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 25px !important;
}

#ClassicModal .ClassicModal-cover {
    position: relative;
    width: 100%;
    height: min-content;
    background-color: white !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 10px;
    overflow: hidden;
}

#ClassicModal .ClassicModal-cover .ClassicModal-top {
    position: relative;
    top: 0;
    z-index: 9;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-left: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    margin-bottom: 25px;
    border-radius: 10px;

}

#ClassicModal .ClassicModal-cover .ClassicModal-top span {
    font-weight: 800;
    font-size: 22px;
}

#ClassicModal .ClassicModal-cover .ClassicModal-top .close-button {
    cursor: pointer;
}

#ClassicModal .ClassicModal-cover .ClassicModal-content {
    /* background-color: brown; */
    padding-bottom: 10px;
}


#ReactModalPortal {}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: #5252525d !important;
}


.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}