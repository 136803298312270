// #GtwList {
//     width: 100%;
//     height: 100%;
// }

// #GtwList .GtwList-cover {
//     width: 100%;
//     height: calc(100% - 10px);
// }

#GtwList .GtwList-cover .GtwList-top_small_info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info {
    margin: 0 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 7px;
    /* background-color: white; */
    background-color: transparent;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    cursor: pointer;
    border: 1px solid transparent;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info.fire-alarm {
    border: 2px #fd571f solid;
    animation: blink 1s;
    animation-iteration-count: infinite;
    position: relative;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info.fire-alarm img {
    position: absolute;
    top: -20px;
    width: 30px;
    height: 30px;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info.open-cover-alarm {
    border: 2px #eccd58 solid;
    animation: blink 1s;
    animation-iteration-count: infinite;
    position: relative;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info.open-cover-alarm img {
    position: absolute;
    top: -20px;
    width: 30px;
    height: 30px;
}

@keyframes blink {
    50% {
        border-color: #fff;
    }
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info > span {
    font-weight: 600;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_info .info span.value {
    color: var(--primary_swatch);
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-all_GtwList-levels {
    /* background-color: white; */
    background-color: transparent;
    margin: 0 10px;
    margin-bottom: 5px;
    overflow: hidden;
    padding-right: 10px;
    cursor: pointer;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-all_GtwList-levels .spans {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-all_GtwList-levels i {
    transform: translateX(-40%);
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level span {
    font-size: 14px;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-low_level {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-low_level span.value {
    color: var(--primary_swatch);
    font-weight: 600;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-normal_level {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-normal_level span.value {
    color: var(--primary_swatch);
    font-weight: 600;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-high_level {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

#GtwList .GtwList-cover .GtwList-top_small_info .GtwList-all_GtwList_level .GtwList-high_level span.value {
    // color: var(--primary_swatch);
    font-weight: 600;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

#GtwList .GtwList-cover .text-center.position-absolute {
    margin-top: 5px;
    font-size: 13px;
}

#GtwList .GtwList-cover .GtwList-bottom-buttons {
    z-index: 999;
    position: relative;
}

#GtwList .GtwList-cover .GtwList-bottom_more_info {
    td {
        font-size: 13px !important;
    }
}
