#Toasty {
    background-color: rgba(0, 0, 0, 0.291);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999998;
}

#Toasty .Toasty_cover {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: auto;
    min-width: 400px;
    max-width: calc(100vw - 100px);
    max-height: calc(100vh - 100px);
    height: auto;
    z-index: 999999;
    border-radius: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#Toasty .Toasty_cover .title {
    border-left: 10px solid;
    border-bottom: 3px solid;
    border-top-right-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

#Toasty .Toasty_cover .title span {
    font-size: 25px;
    color: #febb08;
    font-weight: 700;
}

#Toasty .Toasty_cover .title img {
    margin: 5px 10px;
}


#Toasty .Toasty_cover .Toasty_content {
    background-color: #fffffff8;
    height: 100%;
    padding: 15px;
    /* padding-top: 30px; */
}


#Toasty .Toasty_cover .Toasty_content .Toasty_content_toastyCaption {
    font-size: 20px;
    color: black;
    font-weight: 600;
}

#Toasty .Toasty_cover .Toasty_content .Toasty_content_toastyData {
    margin-top: 15px;
    font-size: 17px;
    color: black;
    font-weight: 500;
    overflow: auto;
}