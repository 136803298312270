#GlobalInfo {
    background-color: red;
    top: 0;
    width: 100vw;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
}
